import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable } from '@angular/core';
@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private readonly afAuth: AngularFireAuth) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.afAuth.idToken.pipe(
      take(1),
      switchMap((token: any) => {
        if (token == null || req.responseType === 'blob') {
          return next.handle(req);
        }
        const newReq = req.clone({
          headers: req.headers.set('x-kwf-auth-token', token).set('Authorization', `Bearer ${token as string}`),
        });
        return next.handle(newReq);
      })
    );
  }
}
