import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { AuthPipe, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { TermsOfServiceComponent } from './privacy-terms-pages/terms-of-service/terms-of-service.component';
import { DataPolicyComponent } from './privacy-terms-pages/data-policy/data-policy.component';
import { CookiePolicyComponent } from './privacy-terms-pages/cookie-policy/cookie-policy.component';
import { UserGuidelinesComponent } from './privacy-terms-pages/user-guidelines/user-guidelines.component';
import { AdminGuard } from './users/admin.guard';
import {LoadImageComponent} from "./common/load-image/load-image.component";

const redirectUnauthorizedToLogin = (): AuthPipe => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'keywords',
    loadChildren: () => import('./keywords/keywords.module').then((m) => m.KeywordsModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'load-image-from-path',
    component: LoadImageComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./subscription/subscription.module').then((m) => m.SubscriptionModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'dashboard/youtube-scan',
    loadChildren: () =>
      import('./projects/youtube-scan/youtube-scan.module').then((m) => m.YoutubeScanModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'dashboard/media-scan',
    loadChildren: () =>
      import('./projects/media-scan/media-scan.module').then((m) => m.MediaScanModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'dashboard/text-scan',
    loadChildren: () =>
      import('./projects/text-scan/text-scan.module').then((m) => m.TextScanModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'dashboard/website-scan',
    loadChildren: () =>
      import('./projects/website-scan/website-scan.module').then((m) => m.WebsiteScanModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'dashboard/document-scan',
    loadChildren: () =>
      import('./projects/document-scan/document-scan.module').then((m) => m.DocumentScanModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./help/help.module').then((m) => m.HelpModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
    canActivate: [AngularFireAuthGuard, AdminGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'flagged-websites',
    loadChildren: () => import('./flagged-websites/flagged-websites.module').then((m) => m.FlaggedWebsitesModule),
    canActivate: [AngularFireAuthGuard, AdminGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'terms-and-conditions',
    component: TermsOfServiceComponent,
  },
  {
    path: 'privacy-policy',
    component: DataPolicyComponent,
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
  },
  {
    path: 'user-guidelines',
    component: UserGuidelinesComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
