<mat-drawer-container class="drawer-container">
  <mat-drawer
    *ngIf="
      !this.router.url.includes('/login') &&
      this.router.url !== '/homepage' &&
      !this.router.url.includes('/subscription') &&
      !this.router.url.includes('/terms-and-conditions') &&
      !this.router.url.includes('/privacy-policy') &&
      !this.router.url.includes('/cookie-policy') &&
      !this.router.url.includes('/user-guidelines') &&
      !this.router.url.includes('/accept-invitation') &&
      !this.router.url.includes('/signup-org') &&
      !this.router.url.includes('/about')
    "
    mode="side"
    [opened]="!sideBarMode"
  >
    <side-bar></side-bar>
  </mat-drawer>
  <mat-drawer-content class="drawer-content">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
