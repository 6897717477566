import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../endpoints';
import { EditUserInterface } from '../common/interfaces/user/create-user.interface';
import { PaymentMethod } from "../common/interfaces/user/user.interface";
import { ProfileService, User } from "./profile.service";
import { map } from "rxjs/operators";

export type Organization = {
  id?: string;
  name: string;
  billingMethod: PaymentMethod;
  ownerId?: string;
  enabledAiFeatures?: boolean;
  size?: number;
  status?: OrganizationStatus;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

export type UpdateOrganization = Partial<Omit<Organization, 'id' | 'ownerId' | 'createdAt' | 'updatedAt' | 'deletedAt'>>;

export type OrganizationHasUsers = {
  organizationId: string;
  userId: string;
  role: OrganizationRolesEnum;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date | null;
}

export enum OrganizationRolesEnum {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member'
}

export enum OrganizationStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export type IShareType = 'ALL' | 'MINE' | 'SHARED'

export type CreateInvitation = {
  email: string;
  role: OrganizationRolesEnum;
}

export type Invitation = {
  email: string;
  organizationId: string;
  role: OrganizationRolesEnum;
  token: string;
  userId?: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date | null;
}

export type AcceptInvitationResponse = {
  invitation: Invitation;
  userId: string;
  isNewUser: boolean;
}

export type TransferOwnershipResponse = {
  newOwnerId: string;
  currentOwnerId: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(public http: HttpClient) {}

  getMyOrganizations(): Promise<Organization[]> {
    return lastValueFrom(this.http.get<Organization[]>(Endpoints.getUserOrganizations()));
  }

  getAllOrganizations(): Promise<Organization[]> {
    return lastValueFrom(this.http.get<Organization[]>(Endpoints.getAllOrganizations()));
  }

  async getAllUsersFromOrganization(organizationId: string, showRemoved: boolean = false): Promise<User[]> {
    return await lastValueFrom(this.http.get<User[]>(Endpoints.getAllUsersFromOrganization(organizationId, showRemoved)));
  }

  async createOrganization(payload: Organization): Promise<{ id: string }> {
    return await lastValueFrom(this.http.post<{ id: string }>(Endpoints.createOrganization(), payload));
  }

  async updateOrganization(organizationId: string, payload: UpdateOrganization): Promise<any> {
    return await lastValueFrom(this.http.put<any>(Endpoints.updateOrganization(organizationId), payload));
  }

  async deleteOrganization(organizationId: string): Promise<void> {
    return await lastValueFrom(this.http.delete<any>(Endpoints.deleteOrganization(organizationId)));
  }

  async restoreOrganization(organizationId: string): Promise<void> {
    return await lastValueFrom(this.http.patch<any>(Endpoints.restoreOrganization(organizationId), null));
  }

  async inviteUserToOrganization(organizationId: string, userData: CreateInvitation): Promise<Invitation> {
    return await lastValueFrom(this.http.post<Invitation>(Endpoints.generateOrganzationInvite(organizationId), userData));
  }

  async acceptInvitation(token: string): Promise<AcceptInvitationResponse> {
    return await lastValueFrom(this.http.get<AcceptInvitationResponse>(Endpoints.acceptInvitation(token)));
  }

  async getAllInvitationsFromOrganization(organizationId: string): Promise<{invitations: Invitation[]}> {
    return await lastValueFrom(this.http.get<{invitations: Invitation[]}>(Endpoints.invitationsFromOrganization(organizationId)));
  }

  async removeUserFromOrganization(organizationId: string, userId: string): Promise<string> {
    return await lastValueFrom(this.http.delete<string>(Endpoints.userFromOrganization(organizationId, userId)));
  }

  async deleteUserInvitationsFromOrganization(organizationId: string, userId: string): Promise<string> {
    return await lastValueFrom(this.http.delete<string>(Endpoints.deleteUserInvitationsFromOrganization(organizationId, userId)));
  }

  async getOrganizationByToken(token: string): Promise<Organization> {
    return await lastValueFrom(this.http.get<Organization>(Endpoints.getOrganizationByToken(token)));
  }

  async transferOwnership(organizationId: string, payload: TransferOwnershipResponse): Promise<null> {
    return await lastValueFrom(this.http.post<null>(Endpoints.transferOwnership(organizationId), {
      ...payload
    }));
  }

  async getUserRole(organizationId: string, userId: string): Promise<{role: string}> {
    return await lastValueFrom(this.http.get<{role: string}>(Endpoints.getUserRole(organizationId, userId)));
  }

  async updateUser(organizationId: string, userId: string, user: EditUserInterface): Promise<string> {
    return await lastValueFrom(this.http.put<string>(Endpoints.userFromOrganization(organizationId, userId), {
      ...user
    }));
  }

  async GetOrganizationAiFeatureStatus(organizationId: string): Promise<boolean> {
    const organization = await lastValueFrom(this.http.get<Organization>(Endpoints.getOrganization(organizationId)));
    return organization.enabledAiFeatures ?? false;
  }

  getSharedProjectsList(organizationId: string, searchName?: string, page = 0, filterType?: IShareType): Observable<{ projects: any[], totalScanProjects: number }> {
    return this.http.get<any>(Endpoints.getScanProjectsByOrg(organizationId, searchName, page, filterType), {observe: 'response'}).pipe(map((response) => {
      console.log('response', response);
      return {
        projects: response.body,
        totalScanProjects: Number(response.headers.get('Kwf_scan_total_projects') ?? 0)
      };
    }));
  }

  async deleteEmptyOrganization(organizationId: string): Promise<void> {
    return await lastValueFrom(this.http.delete<void>(Endpoints.deleteEmptyOrganization(organizationId)));
  }
}
