import { Injectable } from '@angular/core';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { ProfileService } from "./profile.service";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analytics: any;
  private readonly sessionStartTime: Date;
  private loggedOut: boolean = false;
  constructor(private readonly profileService: ProfileService) {
    const analytics = new RudderAnalytics();
    analytics.load('2hOF030f5JXazMrSxk32PNxwjPv', 'https://asawaldstebcll.dataplane.rudderstack.com');
    window.rudderanalytics = analytics;
    window.rudderanalytics.page();
    this.sessionStartTime = new Date();
    window.addEventListener('beforeunload', this.endSession.bind(this));
  }

  identifyUser(userData: any): void {
    if (window.rudderanalytics) {
      window.rudderanalytics.identify(userData.id, {
        name: userData.username,
        email: userData.email,
        role: ''
      });
    } else {
      console.error('RudderStack Analytics: Analytics is not initialized.');
    }
  }

  async identifyLoggedUser(): Promise<void> {
    if (window.rudderanalytics) {
      try {
        const res = await this.profileService.getUser();
        this.identifyUser(res);
      } catch (e) {
        console.error(e);
      }
    } else {
      console.error('RudderStack Analytics: Analytics is not initialized.');
    }
  }

  async trackEvent(eventName: string, properties: any): Promise<void> {
    if (window.rudderanalytics) {
      await this.identifyLoggedUser();
      window.rudderanalytics.track(eventName, properties);
    } else {
      console.error('RudderStack Analytics: Analytics is not initialized.');
    }
  }

  private async endSession(event: BeforeUnloadEvent): Promise<void> {
    if (window.rudderanalytics) {
      await this.identifyLoggedUser();

      if (!this.loggedOut) {
        const sessionEndTime = new Date();
        const duration = moment.duration(moment(sessionEndTime).diff(moment(this.sessionStartTime)));
        const formattedTime = this.formatDuration(duration);
        window.rudderanalytics.track('User Left Page', { duration: formattedTime });
      }
    } else {
      console.error('RudderStack Analytics: Analytics is not initialized.');
    }
  }

  async logout(): Promise<void> {
    this.loggedOut = true;

    if (window.rudderanalytics) {
      await this.identifyLoggedUser();
      const sessionEndTime = new Date();
      const duration = moment.duration(moment(sessionEndTime).diff(moment(this.sessionStartTime)));
      const formattedTime = this.formatDuration(duration);
      window.rudderanalytics.track('User Logged Out', { duration: formattedTime });
    } else {
      console.error('RudderStack Analytics: Analytics is not initialized.');
    }
  }

  formatDuration(duration: any): string {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(seconds);
  }

  pad(num: any): string {
    return (num < 10 ? "0" : "") + num;
  }
}
