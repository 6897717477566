import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  updateSidebarSource$ = new Subject<void>();

  triggerSidebarUpdate(): void {
    this.updateSidebarSource$.next();
  }
}
