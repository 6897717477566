export enum PaymentMethod {
  STRIPE = 'stripe',
  INVOICE = 'invoice'
}

export enum FirstLoginMethod {
  CHANGE_PASSWORD = 'change-password',
  SUBSCRIBE = 'subscribe',
  FALSE = 'false'
}

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
  paymentMethod: PaymentMethod;
  isActive: boolean;
}
