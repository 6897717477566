import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {lastValueFrom, Observable} from 'rxjs';
import {Endpoints} from '../endpoints';
import {BaseKeywordListInterface,} from '../common/interfaces/keyword-list/base-keyword-list.interface';
import {GetKeywordListAPIInterface} from '../common/interfaces/keyword-list/get-keyword-list-API.interface';
import {BaseKeywordInterface} from '../common/interfaces/keyword/base-keyword.interface';
import {KeywordParsedDataInterface} from "../common/interfaces/keyword-list/keyword.interface";
import { IShareType } from "./organization.service";

@Injectable({
  providedIn: 'root',
})
export class KeywordsListService {
  constructor(private readonly http: HttpClient) {
  }

  setBaseKeywordListInterfaceData(
    baseKeywordListInterface: BaseKeywordListInterface
  ): BaseKeywordListInterface {
    return {
      companyId: baseKeywordListInterface.companyId,
      name: baseKeywordListInterface.name,
      keywords: baseKeywordListInterface.keywords,
    };
  }

  async addKeywordToList(listId: string, newKeywordIds: string[]): Promise<string[]> {
    const { keywords } = await lastValueFrom(this.getKeywordListById(listId))
    const oldKeywordsIds = keywords.map((keyword:any) => keyword.id);
    return lastValueFrom(
      this.http.patch<string[]>(`${Endpoints.keywordList()}/${listId}`, { keywords: [...oldKeywordsIds, ...newKeywordIds] })
    );
  }

  async addKeywordList(keywordList: BaseKeywordListInterface): Promise<{ id: string }> {
    return await lastValueFrom(
      this.http.post<{ id: string }>(Endpoints.keywordList(), keywordList)
    );
  }

  editKeywordList(
    id: string,
    keywordList: BaseKeywordListInterface
  ): Promise<BaseKeywordInterface> {
    const body: BaseKeywordListInterface = this.setBaseKeywordListInterfaceData(keywordList);
    return lastValueFrom(
      this.http.patch<BaseKeywordInterface>(`${Endpoints.keywordList()}/${id}`, body)
    );
  }

  removeKeywordFromList(listId: string, keywordId: string): Promise<BaseKeywordListInterface> {
    return lastValueFrom(
      this.http.delete<BaseKeywordListInterface>(
        `${Endpoints.keywordList()}/${listId}/${keywordId}`
      )
    );
  }

  removeAllKeywordFromList(listId: string): Promise<BaseKeywordListInterface> {
    return lastValueFrom(
      this.http.delete<BaseKeywordListInterface>(
        `${Endpoints.keywordList()}/${listId}?delete-keywords=true`
      )
    );
  }

  removeKeywordList(listId: string): Promise<BaseKeywordListInterface> {
    return lastValueFrom(
      this.http.delete<BaseKeywordListInterface>(`${Endpoints.keywordList()}/${listId}`)
    );
  }

  getKeywordList(filterType: IShareType = 'ALL' ): Observable<GetKeywordListAPIInterface[]> {
    return this.http.get<GetKeywordListAPIInterface[]>(Endpoints.organizationKeywordList(), {
      params: { filterType }
    });
  }

  getKeywordListById(id: string): Observable<GetKeywordListAPIInterface> {
    return this.http.get<GetKeywordListAPIInterface>(Endpoints.keywordListById(id));
  }

  async addImportKeywordList(keywordList: KeywordParsedDataInterface[]): Promise<{ id: string }> {
    return await lastValueFrom(
      this.http.post<{ id: string }>(Endpoints.importKeywordList(), keywordList)
    );
  }

  async addSmartSuggestion(keyword: any): Promise<{ id: string }> {
    return await lastValueFrom(
      this.http.post<{ id: string }>(Endpoints.smartSuggestion(), keyword)
    );
  }

  async addSmartVariances(keyword: any): Promise<{ id: string }> {
    return await lastValueFrom(
      this.http.post<{ id: string }>(Endpoints.smartSuggestion(), keyword)
    );
  }

  toggleShare(keywordListId: string): Observable<{ shared: boolean }> {
    return this.http.put<{ shared: boolean }>(`${Endpoints.keywordListToggleShare()}`, { keywordListId });
  }
}
