import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../endpoints';
import firebase from 'firebase/compat/app';
import { EditUserInterface } from '../common/interfaces/user/create-user.interface';
import {PaymentMethod} from "../common/interfaces/user/user.interface";

export type User = {
  id: string;
  email: string;
  username: string;
  isAdmin: boolean;
  paymentMethod: PaymentMethod;
  isActive: boolean;
  tac: boolean;
  cacheTTL: number;
  role?: string;
  updatedAt?: Date;
  deletedAt?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(public http: HttpClient) {}

  getUser(): Promise<void> {
    return lastValueFrom(this.http.get<any>(Endpoints.getUser()));
  }

  getAllUsers(): Observable<(User & Array<{plan: string}>)> {
    return this.http.get<any>(Endpoints.getAllUsers());
  }

  updateUserName(username: string): Promise<void> {
    const body = { username };
    return lastValueFrom(this.http.patch<any>(Endpoints.getUser(), body));
  }

  updateUserPassword(currentPassword: string, newPassword: string): Promise<void> | undefined {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user?.email ? user.email : '',
      currentPassword
    );
    return user?.reauthenticateWithCredential(credential).then(() => {
      this.setNewPassword(user, newPassword);
    });
  }

  setNewPassword(res: any, newPassword: string): void {
    void res.updatePassword(newPassword);
  }

  checkCurrentPassword(currentPassword: string): Promise<void> | undefined {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user?.email ? user.email : '',
      currentPassword
    );
    return user?.reauthenticateWithCredential(credential).then(() => {});
  }

  deleteUser(id: string): Promise<void> {
    return lastValueFrom(this.http.delete<any>(Endpoints.deleteUser(id)));
  }

  editUser(id: string, newEmail: EditUserInterface): Promise<EditUserInterface> {
    return lastValueFrom(this.http.put<any>(Endpoints.editUser(id), newEmail));
  }

  acceptTac(): Promise<void> {
    return lastValueFrom(this.http.patch<any>(Endpoints.getUser(), {tac: true}));
  }

  changeUserPassword(uid: string, newPassword: string): Promise<object> {
    const body = {
      uid,
      password: newPassword
    }
    return lastValueFrom(this.http.patch<any>(Endpoints.changeUserPassword(uid), body));
  }
}
