import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {getUserId} from "rudder-sdk-js";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {Endpoints} from "../../endpoints";

@Component({
    selector: 'app-load-image',
    templateUrl: './load-image.component.html',
    styleUrls: ['./load-image.component.scss'],
    standalone: false
})
export class LoadImageComponent implements OnInit {
  constructor(
    public routeParams: ActivatedRoute,
    readonly storage: AngularFireStorage,
    readonly nav: Router
  ) {}

  ngOnInit(): void {
    this.routeParams.queryParams.subscribe((res) => {
      const onlyLoad = res['onlyLoad'];
      if (onlyLoad) {
        return;
      }

      const path = res['path'];
      const [userId, someId, fileName] = path.split('/');
      console.log(userId, Endpoints.getUserID());
      if (userId === Endpoints.getUserID()) {
        const ocrBucket = this.storage.storage.app.storage(environment.ocrBucket);
        const storageRef = ocrBucket.ref(path);
        storageRef.getDownloadURL().then((url) => {
          console.log(url);
          setTimeout(() => {
            window.open(url, '_self');
          }, 500)
        });
      } else{
        this.nav.navigate(['/dashboard']);
      }
    });
  }
}
