import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './common/shared.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from './services/auth.service';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from '../environments/environment';
import { APIInterceptor } from './common/interceptors/api.interceptor';
import { AuthInterceptor } from './common/interceptors/auth.interceptor';
import { NgChartsModule } from 'ng2-charts';
import { PrivacyTermsPagesModule } from './privacy-terms-pages/privacy-terms-pages.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {LoadImageComponent} from "./common/load-image/load-image.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";

@NgModule({ declarations: [AppComponent, LoadImageComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        MatSidenavModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireStorageModule,
        NgChartsModule,
        PrivacyTermsPagesModule,
        MatSlideToggleModule,
        MatProgressBarModule], providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
