import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorComponent } from '../common/elements/toast/error/error.component';
import { ToastComponent } from '../common/elements/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private readonly _snackBar: MatSnackBar) {}

  callToast(title: string, message: string, opts?: { duration?: number }): void {
    this._snackBar.openFromComponent(ToastComponent, {
      duration: opts?.duration ?? 3000,
      data: { title, message },
      panelClass: ['custom-toast'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  callErrorToast(message: string, duration: number = 3000): void {
    this._snackBar.openFromComponent(ErrorComponent, {
      duration,
      data: message,
      panelClass: ['custom-toast-error'],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
