<div class="side-bar-container">
  <div class="logo-and-links">
    <div class="logo">
      <a>
        <img src="../../../../assets/img/logo.svg" alt="Logo" routerLink="dashboard"/>
      </a>
        <div class="organization">
          <mat-select *ngIf="this.organizations.length > 1"
            [formControl]="selectedOrganizationControl"
            (selectionChange)="handleSelection($event.value)"
          >
            <mat-option *ngFor="let org of organizations" [value]="org">{{ org.name }}</mat-option>
          </mat-select>
          <p *ngIf="this.organizations.length === 1">{{this.organizations[0].name}}</p>
        </div>
    </div>
    <div class="links">
      <a *ngIf="user" routerLink="/dashboard" (click)="onClick('Dashboard')" routerLinkActive="active-link" mat-ripple>
        <div class="core">
        <mat-icon svgIcon="dashboard"></mat-icon>
        Dashboard
        </div>
      </a>
      <a *ngIf="user" routerLink="/keywords/all-keywords" (click)="onClick('All Keywords')" routerLinkActive="active-link" mat-ripple>
        <div class="core">
          <mat-icon svgIcon="key"></mat-icon>
          All Keywords
        </div>
      </a>
      <a *ngIf="user" routerLink="/keywords/keywords-list" (click)="onClick('Keywords List')" routerLinkActive="active-link" mat-ripple>
        <div class="core">
        <mat-icon svgIcon="list"></mat-icon>
        Keywords List
        </div>
      </a>
      <a *ngIf="user && userIsOrgAdmin" [routerLink]="'/organizations/details/' + this.selectedOrganizationControl.value?.id" routerLinkActive="active-link" mat-ripple>
        <div class="core">
          <mat-icon>error_outline</mat-icon>
          Manage {{ this.selectedOrganizationControl.value?.name }}
        </div>
        <span class="org-admin">Org Owner/Admin feature</span>
      </a>
      <a *ngIf="user && user.isAdmin" routerLink="/users/users" routerLinkActive="active-link" mat-ripple>
        <div class="core">
        <mat-icon svgIcon="patient_list"></mat-icon>
        Users
        </div>
        <span>Super Admin feature</span>
      </a>
      <a *ngIf="user && userIsOrgAdmin" routerLink="/organizations/shared-reports" routerLinkActive="active-link" mat-ripple>
        <div class="core">
          <mat-icon svgIcon="report"></mat-icon>
          Shared Reports
        </div>
        <span class="org-admin">Org Owner/Admin feature</span>
      </a>
      <a *ngIf="user && userIsOrgAdmin" routerLink="/organizations/shared-keyword-lists" routerLinkActive="active-link" mat-ripple>
        <div class="core">
          <mat-icon svgIcon="list"></mat-icon>
          Shared Keyword Lists
        </div>
        <span class="org-admin">Org Owner/Admin feature</span>
      </a>
      <a *ngIf="user && user.isAdmin" routerLink="/flagged-websites" routerLinkActive="active-link" mat-ripple>
        <div class="core">
        <mat-icon>error_outline</mat-icon>
        Flagged Websites
        </div>
        <span>Super Admin feature</span>
      </a>
      <a *ngIf="user && user.isAdmin" routerLink="/organizations/all" routerLinkActive="active-link" mat-ripple>
        <div class="core">
          <mat-icon>error_outline</mat-icon>
          Manage Organizations
        </div>
        <span>Super Admin feature</span>
      </a>
    </div>
  </div>
  <div class="footer">
    <div class="profile">
      <div class="user">
        <mat-icon>person</mat-icon
        ><span *ngIf="!showSpinner">{{ user ? user.username : "---" }}</span
      ><app-spinner *ngIf="showSpinner || !user"></app-spinner>
      </div>
      <a routerLink="/profile" (click)="onClick('Manage Account')" *ngIf="!router.url.includes('/profile')"
      >manage account <mat-icon>arrow_forward</mat-icon></a
      >
    </div>
    <div class="logout" (click)="onLogout('Logout'); logout()"><mat-icon fontIcon="logout"></mat-icon> log out</div>
    <div class="help"  (click)="onClick('Help'); openHelp()" mat-ripple><span>?</span> help</div>
  </div>
</div>
